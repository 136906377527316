// currency transforms a number provided in cents into CAD of the appropriate locale
export default function currency(num, locale = 'fr-CA') {
    if (isNaN(parseFloat(num))) {
        return 0
    }
    const numStr = num.toString()
    let maxDigits = 2
    if (
        numStr[numStr.length - 1] === '0' &&
        numStr[numStr.length - 2] === '0'
    ) {
        maxDigits = 0
    }
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits,
    }).format(num / 100)
}
