import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { primary, secondary, h3 } from '../../constants/theme'
import { Link } from 'gatsby'

const buttonStyle = (props) => css`
    background-color: ${props.inverted ? 'transparent' : secondary};
    border-color: ${props.inverted ? 'white' : secondary};
    border: 4px solid ${props.inverted ? 'white' : secondary};
    border-radius: 30px;
    display: block;
    color: white;
    font-size: ${h3};
    font-weight: 700;
    height: inherit;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    max-width: 40rem;
    width: 100%;
    a {
        color: white;
    }
    &:hover {
        color: ${props.inverted ? primary : 'white'};
        background-color: ${props.inverted ? 'white' : primary};
        border: 4px solid ${props.inverted ? 'white' : primary};
        a {
            color: ${props.inverted ? primary : 'white'};
        }
    }
`
const StyledButton = styled.button`
    ${buttonStyle}
`
const StyledLink = styled(Link)`
    ${buttonStyle}
`
const StyledHref = styled.a`
    ${buttonStyle}
`

export default (props) => {
    if (props.href) {
        return <StyledHref {...props}>{props.children}</StyledHref>
    }
    if (props.to) {
        return <StyledLink {...props}>{props.children}</StyledLink>
    }
    return <StyledButton {...props}>{props.children}</StyledButton>
}
