import React from 'react'
import styled from '@emotion/styled'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import lawyerTypes from '../../constants/lawyerTypes'

import Grid from '@material-ui/core/Grid'
import MaterialButton from '@material-ui/core/Button'
import SvgIcon from '@material-ui/core/SvgIcon'
const Button = styled(MaterialButton)`
    && {
        display: flex;
        border-radius: ${(props) => (props.alternate ? '0px' : '30px')};
        height: 60px;
        width: 100%;
        .MuiButton-label {
            flex-direction: column;
        }
    }
`

const LawyerTypes = ({ handleSelect, selectedType, alternate }) => {
    const { t } = useTranslation()
    return (
        <Grid container spacing={2} className="lawyer-type-buttons">
            {lawyerTypes.map((item) => {
                return (
                    <Grid key={item.name} item xs={6} sm={4}>
                        <Button
                            variant="contained"
                            color={
                                selectedType === item.name
                                    ? 'secondary'
                                    : 'primary'
                            }
                            onClick={() => handleSelect(item.name)}
                            alternate={alternate}
                            style={{
                                backgroundColor:
                                    item.name === 'other' ? '#ff4b4b' : null,
                                color: item.name === 'other' ? 'white' : null,
                            }}>
                            <>
                                {alternate && item.icon && (
                                    <SvgIcon
                                        component={item.icon}
                                        style={{ width: '100%' }}
                                    />
                                )}
                                <span>{t(item.label)}</span>
                            </>
                        </Button>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default LawyerTypes
