import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import { Lock } from '@material-ui/icons'
import { Trans, useTranslation, Link } from 'gatsby-plugin-react-i18next'
import MaterialStepper from '@material-ui/core/Stepper'
import MaterialStep from '@material-ui/core/Step'
import MaterialButton from '@material-ui/core/Button'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import ButtonWithArrow from '../../components/ButtonWithArrow'
import Timeslots from './timeslots'
import LawyerTypes from './lawyerTypes'
import Info from './info'
import Case from './case'
import Goals from './goals'
import JuridicalHelp from './juridicalHelp'
import OtherInvolved from './othersInvolved'
import CircularSpinner from '../../components/CircularSpinner'
import Simple from './simple'
import { post } from '../../services/request'
import { primary } from '../../constants/theme'
import { getCookie } from '../../utils/cookies'
import fields from './fields'
import MoreInfoForm from '../MoreInfoForm'
import { specificSteps } from '../MoreInfoForm/specificQuestions/fields'

const LandingForm = ({
    clientName,
    email,
    region,
    description,
    phoneNumber,
    postalCode,
    province,
    lawType,
    othersInvolved,
    noSteps,
    alternate,
    className,
    simple,
    source = 'jurigo',
    expandCallback,
    customSubmitCallback,
    isIframe = false,
    skipAvailabilities = false,
    referrer,
    pub,
    device,
    keyword,
    hideLawType,
    hideOthersInvolved,
    hideDescription,
    forceEmail = false,
    mobileBackground = false,
    specificFirst = false,
    compact = false,
    fullLegalAid = false,
    preForm = false,
    b = '',
}) => {
    const formEl = useRef(null)
    const [activeStep, setActiveStep] = React.useState(0)
    const [lawyerType, setLawyerType] = React.useState('')
    //const [budgetType, setBudgetType] = React.useState('')
    const [goal, setGoal] = React.useState('')
    const [timeslots, setTimeslots] = React.useState({})
    const [formFields, setFormFields] = React.useState({
        ...fields,
        clientName: {
            ...fields.clientName,
            value: clientName,
            valid: clientName ? true : undefined,
        },
        email: {
            ...fields.email,
            value: email,
            valid: email ? true : undefined,
        },
        region: {
            ...fields.region,
            value: region,
            valid: email ? true : undefined,
        },
        caseDescription: {
            ...fields.caseDescription,
            value: description,
            valid: description ? true : undefined,
        },
        phoneNumber: {
            ...fields.phoneNumber,
            value: phoneNumber,
            valid: phoneNumber ? true : undefined,
        },
        postalCode: {
            ...fields.postalCode,
            value: postalCode,
            valid: postalCode ? true : undefined,
        },
        lawyerType: {
            ...fields.lawyerType,
            value: lawType,
            valid: lawType ? true : undefined,
        },
        othersInvolved: {
            ...fields.othersInvolved,
            value: othersInvolved,
            valid: othersInvolved ? true : undefined,
        },
        province: {
            ...fields.province,
            value: province,
            valid: province ? true : undefined,
        },
    })
    const [juridicalHelp, setJuridicalHelp] = React.useState({
        thinks: false,
        revenueRange: '',
        status: '',
        isEligible: false,
        hasLowRevenue: undefined,
        privateLawyer: undefined,
        discountEligible: undefined,
    })
    const [error, setError] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [acceptDistance, setAcceptDistance] = React.useState(false)
    const { t, i18n } = useTranslation()
    const [specificQuestions, setSpecificQuestions] = React.useState({})

    useEffect(() => {
        if (lawType && lawType !== '') {
            setLawyerType(lawType)
            if (!specificFirst) {
                setActiveStep(1)
            }
        }
    }, [lawType])
    useEffect(() => {
        if (isIframe) {
            setTimeout(() => {
                window.parent.postMessage(
                    {
                        eventCode: 'heightChange',
                        newHeight: formEl?.current.clientHeight,
                    },
                    '*'
                )
            }, 32) //setTimeout seems required for validation to get the right height
        }
    })
    const validateStep = (stepNum) => {
        switch (stepNum) {
            case 1:
                if (!formFields.caseDescription.valid) {
                    setFormFields((prevFormFields) => ({
                        ...prevFormFields,
                        caseDescription: {
                            ...prevFormFields.caseDescription,
                            valid: !!prevFormFields.caseDescription.valid,
                        },
                    }))
                    return false
                }
                return true

            case 2:
                if (
                    lawyerType !== 'criminal' &&
                    !formFields.othersInvolved.valid
                ) {
                    setFormFields((prevFormFields) => ({
                        ...prevFormFields,
                        othersInvolved: {
                            ...prevFormFields.othersInvolved,
                            valid: !!prevFormFields.othersInvolved.valid,
                        },
                    }))
                    return false
                }
                return true
            case 5:
                if (Object.keys(timeslots).length > 10) {
                    return false
                } else {
                    return true
                }
            case 6:
                if (
                    !formFields.clientName.valid ||
                    !formFields.phoneNumber.valid ||
                    !formFields.email.valid ||
                    !formFields.region.valid ||
                    !formFields.province.valid
                ) {
                    setFormFields((prevFormFields) => ({
                        ...prevFormFields,
                        clientName: {
                            ...prevFormFields.clientName,
                            valid: !!prevFormFields.clientName.valid,
                        },
                        phoneNumber: {
                            ...prevFormFields.phoneNumber,
                            valid: !!prevFormFields.phoneNumber.valid,
                        },
                        email: {
                            ...prevFormFields.email,
                            valid: !!prevFormFields.email.valid,
                        },
                        region: {
                            ...prevFormFields.region,
                            valid: !!prevFormFields.region.valid,
                        },
                        province: {
                            ...prevFormFields.province,
                            valid: !!prevFormFields.province.valid,
                        },
                    }))
                    return false
                } else {
                    return true
                }
            default:
                return true
        }
    }

    const sortTimeslots = (timeslots) => {
        const dateOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }
        const times = {
            8: '8h00',
            9: '9h00',
            10: '10h00',
            11: '11h00',
            13: '13h00',
            14: '14h00',
            15: '15h00',
            16: '16h00',
        }
        return Object.keys(timeslots)
            .map((ts) => {
                const datetime = new Date(Date.parse(ts))
                const dayString = datetime.toLocaleDateString('fr', dateOptions)
                const hourString =
                    times[datetime.getHours()] || `${datetime.getHours()}h00`

                return {
                    datestring: `${dayString} à ${hourString}`,
                    date: datetime,
                }
            })
            .sort((a, b) => a.date - b.date)
    }

    const reduceJuridicalHelp = () => {
        if (lawyerType === 'business' || juridicalHelp.privateLawyer === true) {
            return false
        }
        return (
            juridicalHelp.thinks !== 'no' &&
            juridicalHelp.hasLowRevenue === true &&
            (juridicalHelp.revenueRange === 1 ||
                juridicalHelp.revenueRange === 8 ||
                juridicalHelp.revenueRange === 9)
        )
    }
    const submitSimple = () => {
        if (
            !formFields.clientName.valid ||
            !formFields.phoneNumber.valid ||
            !formFields.email.valid ||
            !formFields.region.valid
        ) {
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                clientName: {
                    ...prevFormFields.clientName,
                    valid: !!prevFormFields.clientName.valid,
                },
                phoneNumber: {
                    ...prevFormFields.phoneNumber,
                    valid: !!prevFormFields.phoneNumber.valid,
                },
                email: {
                    ...prevFormFields.email,
                    valid: !!prevFormFields.email.valid,
                },
                region: {
                    ...prevFormFields.region,
                    valid: !!prevFormFields.region.valid,
                },
            }))
            return false
        }

        submit()
    }

    const submit = () => {
        if (!loading) {
            const jh = reduceJuridicalHelp()
            const lt =
                formFields.province.value === 'ON'
                    ? 'on-' + lawyerType
                    : lawyerType
            let {
                caseDescription,
                othersInvolved,
                ...postedSpecificQuestions
            } = specificQuestions

            if (othersInvolved === '' || othersInvolved === undefined) {
                othersInvolved =
                    lt === 'criminal'
                        ? 'DPCP'
                        : lt === 'on-criminal' || lt === 'on-criminal-transport'
                        ? 'Ministry of the Attorney General'
                        : formFields.othersInvolved.value
            }
            if (caseDescription === '' || caseDescription === undefined) {
                caseDescription = formFields.caseDescription.value
            }

            const campaignID = pub || getCookie('campaignID')
            post(
                `/request-meeting`,
                JSON.stringify({
                    timeslots: sortTimeslots(timeslots),
                    email: formFields.email.value,
                    clientName: formFields.clientName.value,
                    othersInvolved: othersInvolved,
                    description: caseDescription,
                    phoneOrInPerson: 'phone',
                    phoneNumber: formFields.phoneNumber.value,
                    juridicalHelp: jh,
                    region: formFields.region.value,
                    lawyerType: lt,
                    //budgetType: budgetType === 0 ? 'no' : budgetType,
                    revenueRange:
                        juridicalHelp.revenueRange > 0
                            ? juridicalHelp.revenueRange
                            : 0,
                    direct: source === 'jurigo' ? true : false,
                    acceptDistance: formFields.acceptDistance.value,
                    specificQuestions: postedSpecificQuestions,
                    goal: goal,
                    source: source,
                    lang: i18n.language ? i18n.language : 'fr',
                    forceEmail: isIframe || forceEmail,
                    discountEligible: juridicalHelp.discountEligible,
                    campaignID: campaignID === '' ? 'SEO' : campaignID,
                    keyword: keyword || getCookie('keyword'),
                    device: device || getCookie('device'),
                    referrer: referrer || getCookie('referrer'),
                    userJourney: getCookie('userJourney'),
                    acceptEnglishLawyer: formFields.acceptEnglishLawyer.value,
                })
            )
                .then((res) => {
                    if (res.status === 200) {
                        const langPrefix = i18n.language === 'en' ? '/en' : ''
                        const jhPrefix = jh ? 'aj' : 'mp'
                        if (customSubmitCallback) {
                            customSubmitCallback({
                                eventCode: jh ? 'submit-aj' : 'submit',
                                hrefParams: `?lawyerType=${lawyerType}&uuid=${
                                    res.parsedJSON.uuid
                                }&moreInfo=${specificFirst ? true : false}`,
                                query: `${langPrefix}/confirmation-${jhPrefix}/?lawyerType=${lawyerType}&uuid=${
                                    res.parsedJSON.uuid
                                }&postFormOnly=${
                                    specificFirst ? true : false
                                }&b=${b}`,
                            })
                            return
                        }

                        if (
                            typeof gtag !== 'undefined' &&
                            !window.CUSTOM_GTM_TRACKER_CONVERSION
                        ) {
                            window.gtag('event', 'submit', {
                                event_action: 'submit',
                            })
                            window.gtag('event', `conv-${jhPrefix}`, {
                                transaction_id: res.parsedJSON.uuid,
                                lawyerType: lawyerType,
                                lang: langPrefix === 'en' ? langPrefix : 'fr',
                                event_action: 'submit-form',
                            })
                            window.gtag('event', `conv2-${jhPrefix}`, {
                                event_action: 'submit-form-2',
                            })

                            window.gtag('event', `confirmation_${jhPrefix}`, {
                                transaction_id: res.parsedJSON.uuid,
                                lawyerType: lawyerType,
                                lang: langPrefix === 'en' ? langPrefix : 'fr',
                            })
                            window.CUSTOM_GTM_TRACKER_CONVERSION = true // prevents conversion from firing twice
                        }
                        // BING
                        window.uetq = window.uetq || []
                        window.uetq.push('event', 'submit_lead_form', {})

                        setTimeout(() => {
                            navigate(
                                `${langPrefix}/confirmation-${jhPrefix}/?lawyerType=${lawyerType}&uuid=${
                                    res.parsedJSON.uuid
                                }&postFormOnly=${
                                    specificFirst ? true : false
                                }&b=${b}`
                            )
                        }, 150)
                    } else {
                        throw new Error(res)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    if (typeof Sentry !== 'undefined') {
                        Sentry.captureException(err)
                    }
                    alert(
                        t('Il y a eu une erreur, veuillez essayer plus tard.')
                    )
                })
        }
    }

    const handleJuridicalHelpChange = (name, value) => {
        if (name === 'status' || name === 'revenueRange') {
            setJuridicalHelp((prevJuridicalHelp) => ({
                ...prevJuridicalHelp,
                [name]: value,
                hasLowRevenue: undefined,
                discountEligible: undefined,
            }))
            return
        }
        if (name === 'discountEligible') {
            setJuridicalHelp((prevJuridicalHelp) => ({
                ...prevJuridicalHelp,
                [name]: value,
                hasLowRevenue: undefined,
            }))
            return
        }
        if (name === 'hasLowRevenue') {
            setJuridicalHelp((prevJuridicalHelp) => ({
                ...prevJuridicalHelp,
                [name]: value,
                discountEligible: undefined,
            }))
            return
        }
        setJuridicalHelp((prevJuridicalHelp) => ({
            ...prevJuridicalHelp,
            [name]: value,
        }))

        if (
            (name === 'thinks' && value === 'no') ||
            (name === 'privateLawyer' && value === true)
        ) {
            handleNext()
        }
    }

    const handleSelectLawyer = (name) => {
        setLawyerType(name)
        handleNext()
    }

    const handleSelectTimeslot = (evt) => {
        if (evt === null) {
            setTimeslots({})
            saveFieldState('meetAsap', true, true)
            handleNext()
            return
        } else if (formFields.meetAsap.value === true) {
            saveFieldState('meetAsap', false, true)
        }
        const datetime = new Date(
            Date.parse(evt.currentTarget.getAttribute('data-datetime'))
        )

        datetime.setMinutes(0)
        datetime.setSeconds(0)

        const ts = {
            ...timeslots,
        }

        if (ts[datetime]) {
            delete ts[datetime]
        } else {
            ts[datetime] = true
        }
        setTimeslots(ts)
    }

    const saveFieldState = (name, value, valid) => {
        let sideEffectChange = {}
        if (name === 'province') {
            sideEffectChange = {
                region: {
                    valid: undefined,
                    value: '',
                },
            }
        }
        setFormFields((prevFormFields) => ({
            ...prevFormFields,
            ...sideEffectChange,
            [name]: {
                ...prevFormFields[name],
                valid,
                value,
            },
        }))
    }

    const handleNext = () => {
        if (simple) {
            return
        }
        const max = specificFirst ? steps.length + 1 : steps.length - 1

        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === max) {
                // do this check before validation to set loading ASAP for no duplicate button clicks
                setLoading(true)
            }
            const validStep = specificFirst
                ? prevActiveStep === max
                    ? validateStep(6)
                    : true
                : validateStep(prevActiveStep)

            if (validStep) {
                if (prevActiveStep === max) {
                    submit()
                    return prevActiveStep
                }

                if (typeof gtag !== 'undefined') {
                    window.gtag('event', 'form_next', {
                        sent_to: 'G-39S10Q89SV',
                        event_action: steps[prevActiveStep]?.code,
                    })
                }

                if (isIframe) {
                    console.log('IFRAME NEXT')
                    parent.postMessage(
                        {
                            eventCode: 'form-next',
                            step: steps[prevActiveStep]?.code,
                        },
                        '*'
                    )
                }

                if (lawyerType === 'corp-start' && prevActiveStep === 0) {
                    return 2
                }
                if (
                    lawyerType === 'criminal' &&
                    steps[prevActiveStep + 1]?.code === 'other-parties'
                ) {
                    return prevActiveStep + 3
                }
                if (
                    lawyerType === 'business' &&
                    (steps[prevActiveStep + 1]?.code === 'other-parties' ||
                        steps[prevActiveStep + 1]?.code === 'legalAid')
                ) {
                    return 6
                }
                if (steps[prevActiveStep + 1]?.code === 'objective') {
                    return prevActiveStep + 2
                }
                if (
                    skipAvailabilities &&
                    steps[prevActiveStep + 1]?.code === 'availabilities'
                ) {
                    return prevActiveStep + 2
                }
                return prevActiveStep + 1
            }
            setLoading(false)
            return prevActiveStep
        })
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            if (typeof gtag !== 'undefined') {
                window.gtag('event', 'form_back', {
                    sent_to: 'G-39S10Q89SV',
                    event_action: steps[prevActiveStep]?.code,
                })
            }
            if (isIframe) {
                parent.postMessage(
                    {
                        eventCode: 'form-back',
                        step: steps[prevActiveStep]?.code,
                    },
                    '*'
                )
                console.log('IFRAME back')
            }
            window.scrollTo(0, 0)
            if (lawyerType === 'corp-start' && prevActiveStep === 2) {
                return 0
            }
            if (
                lawyerType === 'criminal' &&
                (steps[prevActiveStep - 1]?.code === 'other-parties' ||
                    steps[prevActiveStep - 1]?.code === 'objective')
            ) {
                return 1
            }

            if (
                lawyerType === 'business' &&
                (steps[prevActiveStep - 1]?.code === 'other-parties' ||
                    steps[prevActiveStep - 1]?.code === 'legalAid' ||
                    steps[prevActiveStep - 1]?.code === 'availabilities')
            ) {
                return 1
            }
            if (
                skipAvailabilities &&
                steps[prevActiveStep - 1]?.code === 'availabilities'
            ) {
                return prevActiveStep - 2
            }
            if (steps[prevActiveStep - 1]?.code === 'objective') {
                return prevActiveStep - 2
            }
            return prevActiveStep - 1
        })
    }

    const handleJump = (step) => {
        //setActiveStep(step)
    }

    const handleSelectBudgetType = (budget) => {
        setBudgetType(budget)
        handleNext()
    }

    const handleSelectGoal = (goal) => {
        setGoal(goal)
        handleNext()
    }

    const steps = specificFirst
        ? specificSteps[lawyerType]
        : [
              {
                  code: 'lawType',
                  label: t('Quel domaine de droit concerne votre affaire?'),
                  component: (
                      <LawyerTypes
                          handleSelect={handleSelectLawyer}
                          selectedType={lawyerType}
                          alternate={alternate}
                      />
                  ),
                  noButtons: true,
              },
              {
                  code: 'description',
                  label: t(`exposeYourSituation`),
                  component: (
                      <Case
                          saveFieldState={saveFieldState}
                          fields={formFields}
                      />
                  ),
              },
              {
                  code: 'other-parties',
                  label: t(`Quel est le nom de l’autre partie impliquée?`),
                  component: (
                      <OtherInvolved
                          saveFieldState={saveFieldState}
                          fields={formFields}
                      />
                  ),
              },
              {
                  code: 'objective',
                  label: t(`Quel est votre objectif en consultant un avocat?`),
                  component: (
                      <Goals
                          handleSelect={handleSelectGoal}
                          selectedType={goal}
                      />
                  ),
              },
              {
                  code: 'legalAid',
                  label: t(
                      `Êtes-vous admissible à l'aide juridique gouvernementale?`
                  ),
                  component: (
                      <JuridicalHelp
                          handleChange={handleJuridicalHelpChange}
                          values={juridicalHelp}
                          province={formFields.province}
                          saveFieldState={saveFieldState}
                      />
                  ),
              },
              {
                  code: 'availabilities',
                  label: t('availabilitiesMeetLawyer'),
                  component: (
                      <Timeslots
                          timeslots={timeslots}
                          handleSelectTimeslot={handleSelectTimeslot}
                          meetAsap={formFields.meetAsap}
                      />
                  ),
              },
              {
                  code: 'contact-info',
                  label: t('lastStep'),
                  component: (
                      <Info
                          saveFieldState={saveFieldState}
                          fields={formFields}
                          //   acceptDistance={acceptDistance}
                          //   setAcceptDistance={setAcceptDistance}
                      />
                  ),
              },
          ]

    return (
        <Grid
            ref={formEl}
            item
            xs={12}
            container
            style={{ margin: '0 auto', zIndex: 10 }}
            className={simple ? 'simple-form' : 'dynamic-form'}>
            {!noSteps && !simple && (
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ backgroundColor: 'transparent' }}>
                    {steps.map(({ label, component, noButtons }, i) => (
                        <Step
                            key={label}
                            label={label}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleJump={handleJump}
                            stepNum={i}
                            noButtons={noButtons}
                            max={
                                specificFirst
                                    ? steps?.length + 1
                                    : steps.length - 1
                            }
                            activeStep={activeStep}></Step>
                    ))}
                </Stepper>
            )}
            {specificFirst ? (
                <Content mobileBackground={mobileBackground}>
                    <MoreInfoForm
                        lawyerType={lawType}
                        uuid="lol"
                        withSteps={true}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        preFormOnly={specificFirst}
                        setSpecificQuestions={setSpecificQuestions}
                        caseDescription={formFields.caseDescription}
                    />

                    {specificSteps[lawyerType]?.length - activeStep === 0 ? (
                        <div>
                            <h2>
                                {t(
                                    `Êtes-vous admissible à l'aide juridique gouvernementale?`
                                )}
                            </h2>
                            <JuridicalHelp
                                handleChange={handleJuridicalHelpChange}
                                values={juridicalHelp}
                                province={formFields.province}
                                saveFieldState={saveFieldState}
                            />
                        </div>
                    ) : null}

                    {specificSteps[lawyerType]?.length - activeStep === -1 ? (
                        <>
                            <h2>{t('lastStep')}</h2>
                            <Info
                                saveFieldState={saveFieldState}
                                fields={formFields}
                                // acceptDistance={acceptDistance}
                                // setAcceptDistance={setAcceptDistance}
                            />
                        </>
                    ) : null}
                    <SteppingButtons
                        activeStep={activeStep}
                        max={
                            specificFirst ? steps?.length + 1 : steps.length - 1
                        }
                        handleBack={handleBack}
                        handleNext={handleNext}
                        loading={loading}
                        isIframe={isIframe}
                    />
                </Content>
            ) : null}
            {!simple && !specificFirst && !preForm && (
                <Content mobileBackground={mobileBackground}>
                    <h2>{steps[activeStep].label}</h2>
                    {steps[activeStep].component}
                    {!steps[activeStep].noButtons && (
                        <SteppingButtons
                            activeStep={activeStep}
                            max={
                                specificFirst ? steps.length : steps.length - 1
                            }
                            handleBack={handleBack}
                            handleNext={handleNext}
                            loading={loading}
                            isIframe={isIframe}
                        />
                    )}
                </Content>
            )}

            {simple && (
                <Content mobileBackground={mobileBackground}>
                    <Simple
                        fields={formFields}
                        saveFieldState={saveFieldState}
                        juridicalHelp={juridicalHelp}
                        handleJuridicalHelpChange={handleJuridicalHelpChange}
                        lawyerType={lawyerType}
                        setLawyerType={setLawyerType}
                        timeslots={timeslots}
                        handleSelectTimeslot={handleSelectTimeslot}
                        submit={submitSimple}
                        expandCallback={expandCallback}
                        hideLawType={hideLawType}
                        hideOthersInvolved={hideOthersInvolved}
                        hideDescription={hideDescription}
                        fullLegalAid={fullLegalAid}
                        compact={compact}
                    />
                </Content>
            )}
        </Grid>
    )
}

const Stepper = styled(MaterialStepper)`
    && {
        padding: 24px 0;
        > div {
            > div {
                top: 10px;
            }
        }
        @media (max-width: 768px) {
            > div {
                > div {
                    top: 9px;
                }
            }
        }
    }
`

const SubmitButtonWrapper = styled.div`
    font-weight: 600;
    margin: 10px auto 0;
    && {
        img {
            right: -35px;
            bottom: 50px;
        }

        @media (max-width: 767px) {
            button {
                font-size: 1rem;
            }
            img {
                right: -20px;
            }
        }
        @media (max-width: 374px) {
            button {
                font-size: 0.9rem;
            }
        }
    }
`

const Step = ({
    children,
    label,
    handleBack,
    handleNext,
    handleJump,
    noButtons,
    stepNum,
    max,
    activeStep,
    ...props
}) => {
    return (
        <MaterialStep {...props}>
            <StepLabel onClick={() => handleJump(stepNum)}></StepLabel>
        </MaterialStep>
    )
}
Step.defaultProps = {
    noButtons: false,
}

const interruptLink = (evt, isIframe) => {
    if (isIframe) {
        evt.preventDefault()
        parent.postMessage({ eventCode: 'terms' }, '*')
    }
}

const NextOrSubmitButton = ({
    handleNext,
    activeStep,
    max,
    loading,
    isIframe,
}) => {
    const { t } = useTranslation()
    if (max === activeStep) {
        return (
            <>
                <SubmitButtonWrapper>
                    <ButtonWithArrow
                        variant={'contained'}
                        color={'secondary'}
                        onClick={handleNext}
                        disabled={loading}
                        id="submit-button"
                        fullWidth={true}
                        style={{ borderRadius: '4px' }}>
                        <Trans>Trouver votre avocat</Trans>

                        {loading && <CircularSpinner size={20} />}
                    </ButtonWithArrow>
                </SubmitButtonWrapper>
                <small style={{ textAlign: 'center', marginTop: '10px' }}>
                    <strong>
                        <Trans>Rapide, Gratuit, Confidentiel</Trans>
                    </strong>
                </small>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        fontSize: '12px',
                        justifyContent: 'center',
                        margin: '15px 0',
                        textAlign: 'center',
                    }}>
                    <Lock color="secondary" />
                    <small
                        style={{
                            fontSize: '12px',

                            textAlign: 'center',
                        }}>
                        <Trans>
                            En cliquant sur le bouton, vous acceptez les
                        </Trans>{' '}
                        <a
                            href={t('/termes-et-conditions')}
                            style={{ fontSize: '12px' }}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(evt) => {
                                interruptLink(evt, isIframe)
                            }}>
                            <Trans>termes et conditions</Trans>
                        </a>
                    </small>
                </div>
            </>
        )
    }
    return (
        <MaterialButton
            variant={'outlined'}
            color={'primary'}
            onClick={handleNext}
            style={{ float: 'right' }}
            disabled={loading}>
            <Trans>Suivant</Trans>
        </MaterialButton>
    )
}

const SteppingButtons = ({ ...props }) => {
    const { handleNext, handleBack, activeStep, max, loading, isIframe } = props
    return (
        <div
            style={{
                minHeight: max === activeStep ? '151px' : '55px',
                width: '100%',
            }}>
            <div
                style={{
                    paddingTop: '15px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection:
                        activeStep === max ? 'column' : 'row-reverse',
                }}
                className="step-buttons">
                <NextOrSubmitButton
                    activeStep={activeStep}
                    handleNext={handleNext}
                    loading={loading}
                    max={max}
                    isIframe={isIframe}
                />
                <MaterialButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{ float: 'right' }}>
                    <Trans>Retour</Trans>
                </MaterialButton>
            </div>
        </div>
    )
}

const Content = styled.div`
    min-height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column nowrap;
    padding: 20px 20px;
    background: #ffffffc9;
    border: ${`2px solid ${primary}`};
    border-radius: 30px;
    position: relative;
    width: 94vw;
    max-width: 600px;
    margin: 0 auto;
    z-index: 10;
    h2 {
        margin-bottom: 5px;
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }
    .MuiTextField-root > div > div.MuiSelect-select {
        max-width: 68vw;
    }
    @media (max-width: 767px) {
        /* min-height: 300px;
        display: block; */
        border: ${(props) => (props.mobileBackground ? '' : 'none')};
        background: ${(props) => (props.mobileBackground ? '' : 'none')};
        h2 {
            font-size: 18px;
            margin-bottom: 15px;
        }
        .MuiGrid-grid-xs-4 > button > span {
            font-size: 0.7rem;
        }
        button span {
            font-size: 0.8rem;
        }
    }
    .step-buttons {
        /* bottom: 35px;
        position: absolute;
        width: calc(100% - 35px) !important; */
        margin-top: auto;
        @media (max-width: 767px) {
            button span {
                font-size: 1rem;
            }
        }
    }
`

export default LandingForm
