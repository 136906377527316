import React from 'react'
import { Link } from 'gatsby'
import {
    Grid,
    MenuItem,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import TextField from '../../components/TextField'
import regions, { regionsON } from '../../constants/regions'
import lawyerTypes from '../../constants/lawyerTypes'
import { TextFormatRounded, Lock } from '@material-ui/icons'
import JuridicalHelp from './juridicalHelp'
import Timeslots from './timeslots'
import styled from '@emotion/styled'
import BlueArrow from '../../images/blue-arrow.png'
import ButtonWithArrow from '../../components/ButtonWithArrow'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const nonDigits = new RegExp(/[^0-9\-\s]/g)
const maskPhoneNumber = (phoneNumber) => phoneNumber.replace(nonDigits, '')

const CTA = styled.div`
    && {
        position: relative;
        width: 100%;
        padding: 10px;
        button {
            width: 100%;
        }

        span {
            color: black !important;
            font-size: 1.2rem;
            font-weight: 600;
        }
        > div {
            display: inline;
            position: unset;
            top: -60px;
            width: 400px;
            span {
                display: inline-block;
                margin-top: -3rem;

                width: 6rem;
            }
            img {
                bottom: 90px;
                width: 50px;
            }
            button {
                border-radius: 0;
            }
        }
        @media (max-width: 667px) {
            > div {
                img {
                    bottom: 90px;
                    right: -12px;
                    width: 30px;
                }
            }
        }
    }
`

const SimpleForm = ({
    fields,
    saveFieldState,
    juridicalHelp,
    handleJuridicalHelpChange,
    lawyerType,
    setLawyerType,
    timeslots,
    handleSelectTimeslot,
    submit,
    expandCallback,
    hideLawType,
    hideOthersInvolved,
    hideDescription,
    compact,
    fullLegalAid,
}) => {
    const regionsValues = fields.province.value === 'ON' ? regionsON : regions
    const smSize = compact ? 4 : 6
    const { t } = useTranslation()
    return (
        <Grid container spacing={2} style={{ padding: 0 }}>
            <Grid item xs={12} sm={smSize}>
                <TextField
                    fullWidth
                    helperText={'Veuillez entrer votre nom complet.'}
                    id="clientName"
                    name="clientName"
                    className="textInput"
                    label="Nom complet"
                    autoComplete="name"
                    onChange={saveFieldState}
                    {...fields.clientName}
                />
            </Grid>
            <Grid item xs={12} sm={smSize}>
                <TextField
                    fullWidth
                    helperText={'Veuillez entrer votre courriel.'}
                    id="email"
                    name="email"
                    className="textInput"
                    label="Courriel"
                    autoComplete="email"
                    type="email"
                    onChange={saveFieldState}
                    {...fields.email}
                />
            </Grid>
            <Grid item xs={12} sm={smSize}>
                <TextField
                    fullWidth
                    helperText={
                        'Veuillez entrez un numéro de téléphone valide (xxx-xxx-xxxx).'
                    }
                    id="phoneNumber"
                    name="phoneNumber"
                    className="textInput"
                    label="Numéro de téléphone"
                    autoComplete="tel-national"
                    onChange={saveFieldState}
                    maskingFn={maskPhoneNumber}
                    {...fields.phoneNumber}
                />
            </Grid>

            {!hideLawType ? (
                <Grid item xs={12} sm={smSize}>
                    <TextField
                        select
                        fullWidth
                        helperText={'Vous devez sélectionner une expertise.'}
                        id="lawyerType"
                        name="lawyerType"
                        className="textInput"
                        label="Expertise recherchée?"
                        autoComplete="off"
                        onChange={(_, value) => {
                            if (expandCallback) {
                                expandCallback()
                            } else {
                                setLawyerType(value)
                            }
                        }}
                        {...fields.lawyerType}
                        style={{ textAlign: 'left' }}>
                        {lawyerTypes.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            ) : null}

            {lawyerType === '' ? null : (
                <>
                    <JuridicalHelp
                        handleChange={handleJuridicalHelpChange}
                        values={juridicalHelp}
                        province={fields.province}
                        saveFieldState={saveFieldState}
                        simple={fullLegalAid ? false : true}
                    />
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            helperText={'Vous devez sélectionner une région'}
                            id="region"
                            name="region"
                            className="textInput"
                            label="Région"
                            autoComplete="off"
                            onChange={saveFieldState}
                            {...fields.region}>
                            {regionsValues.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {!hideOthersInvolved ? (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                helperText={
                                    'Veuillez entrez le nom des autres parties impliquées'
                                }
                                id="othersInvolved"
                                name="othersInvolved"
                                className="textInput"
                                label="Autres parties impliquées"
                                autoComplete="off"
                                onChange={saveFieldState}
                                {...fields.othersInvolved}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fields.acceptDistance.value}
                                    onChange={() => {
                                        saveFieldState(
                                            'acceptDistance',
                                            !fields.acceptDistance.value,
                                            true
                                        )
                                    }}
                                    name="acceptDistance"
                                />
                            }
                            label={
                                <span>
                                    <strong>
                                        <Trans>
                                            J'accepte d'être servi à distance,
                                            même à l'extérieur de ma région
                                        </Trans>
                                    </strong>{' '}
                                    <br />
                                    <small>{t('optionIncreaseChances')}</small>
                                </span>
                            }
                        />
                    </Grid>
                </>
            )}

            {
                /*lawyerType !== '' */ 1 === 0 ? (
                    <>
                        <Timeslots
                            timeslots={timeslots}
                            handleSelectTimeslot={handleSelectTimeslot}
                        />
                        {lawyerType !== 'business' ? (
                            <div style={{ margin: '25px 0', width: '100%' }}>
                                <JuridicalHelp
                                    handleChange={handleJuridicalHelpChange}
                                    values={juridicalHelp}
                                    province={fields.province}
                                    saveFieldState={saveFieldState}
                                    simple={true}
                                />
                            </div>
                        ) : null}

                        {!hideOthersInvolved ? (
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    helperText={
                                        'Veuillez entrez le nom des autres parties impliquées'
                                    }
                                    id="othersInvolved"
                                    name="othersInvolved"
                                    className="textInput"
                                    label="Autres parties impliquées"
                                    autoComplete="off"
                                    onChange={saveFieldState}
                                    {...fields.othersInvolved}
                                />
                            </Grid>
                        ) : null}
                    </>
                ) : null
            }

            {!hideDescription ? (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        helperText={'Veuillez expliquer votre cas brièvement.'}
                        id="caseDescription"
                        name="caseDescription"
                        className="textInput"
                        label="Décrivez brièvement votre dossier/situation"
                        autoComplete="off"
                        onChange={saveFieldState}
                        multiline={true}
                        rows={5}
                        {...fields.caseDescription}
                    />
                </Grid>
            ) : null}
            <CTA>
                <ButtonWithArrow
                    variant="contained"
                    color="secondary"
                    onClick={submit}>
                    Trouver mon avocat!
                </ButtonWithArrow>
            </CTA>
            <Grid
                item
                xs={12}
                style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Lock color="secondary" />
                <span style={{ fontSize: '0.7rem' }}>
                    En cliquant sur le bouton, vous acceptez les{' '}
                    <Link style={{ color: 'black' }} to="/termes-et-conditions">
                        <small>termes et conditions</small>
                    </Link>
                    .
                </span>
            </Grid>
        </Grid>
    )
}

export default SimpleForm
