import React, { Fragment } from 'react'
import styled from '@emotion/styled'

import MaterialTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Button as MaterialButton, Grid } from '@material-ui/core'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const times = {
    8: '8h00',
    9: '9h00',
    10: '10h00',
    11: '11h00',
    12: 'midi',
    13: '13h00',
    14: '14h00',
    15: '15h00',
    16: '16h00',
}

const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1)
}

const Button = styled(MaterialButton)`
    && {
        height: 50px;
        border-radius: 30px;
        width: 100%;
        .MuiFormControl {
            margin-top: 10px;
        }
    }
`

const Day = styled.div`
    display: flex;
    font-size: 0.8rem;
    text-align: center;
    flex-flow: column nowrap;
    padding: 0.1rem 0.5rem;
    width: 110px;
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const Tabs = styled(MaterialTabs)`
    @media (max-width: 667px) {
        margin-top: -20px;
    }
`

const Timeslot = styled(MaterialButton)`
    && {
        height: 30px !important;
        border-radius: 30px;
        margin: 4px auto;
        padding: 2px;
        width: 64px;
    }
`

const DaysWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: unset !important;
    overflow-x: scroll;
    @media (min-width: 700px) {
        overflow-x: initial;
    }
`

const Timeslots = ({ ...props }) => {
    const next7Days = [null, null, null, null, null, null, null]
    const { tabView, timeslots, handleSelectTimeslot } = props
    const now = new Date(Date.now())
    const { t, i18n } = useTranslation()
    const lang = i18n.language === 'en' ? 'en' : 'fr'

    return next7Days.map((v, dayNum) => {
        // i = day number
        const dayOffset = dayNum + tabView * 7
        const today = now.getDate()
        const datetime = new Date(Date.now() + 3600 * 1000 * 24 * dayOffset) // return a date as of the #dayOffset
        datetime.setMinutes(0)
        datetime.setSeconds(0)
        const day = datetime.getDay()
        const dateString = datetime.toLocaleDateString(lang, dateOptions)
        if (day === 0 || day === 6) return null // no weekends
        return (
            <Day key={dayNum + ' ' + tabView}>
                <div style={{ minHeight: '36px' }}>
                    {capitalize(dateString)}
                </div>
                {Object.keys(times).map((time) => {
                    const d = new Date(datetime)
                    d.setHours(time)

                    const disabled = (() => {
                        if (
                            today === d.getDate() &&
                            now.getHours() + 1 >= time
                        ) {
                            return true
                        }
                        return false
                    })()

                    const variant = timeslots[d] ? 'contained' : 'outlined'

                    return (
                        <Timeslot
                            key={time}
                            disabled={disabled}
                            variant={variant}
                            color="secondary"
                            data-datetime={d}
                            onClick={handleSelectTimeslot}>
                            {t(times[time])}
                        </Timeslot>
                    )
                })}
            </Day>
        )
    })
}

const TS = ({ timeslots, handleSelectTimeslot, meetAsap = {} }) => {
    const [tabView, setTabView] = React.useState(0)
    const [showTimes, setShowTimes] = React.useState(meetAsap.value === false)
    const { t } = useTranslation()
    const changeTab = (event, value) => {
        setTabView(value)
    }

    const enoughSelected =
        Object.keys(timeslots).length >= 1 &&
        Object.keys(timeslots).length <= 10

    return (
        <Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        color={showTimes === true ? 'secondary' : 'default'}
                        onClick={() => {
                            setShowTimes(true)
                        }}>
                        <Trans>Choisir dates</Trans>
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        style={{ marginBottom: '20px' }}
                        variant="contained"
                        color={
                            meetAsap.value && !showTimes
                                ? 'secondary'
                                : 'primary'
                        }
                        onClick={() => handleSelectTimeslot(null)}>
                        <Trans>Le plus rapidement possible</Trans>
                    </Button>
                </Grid>
            </Grid>
            {showTimes ? (
                <>
                    <div>
                        <small
                            style={{
                                color: enoughSelected ? 'inherit' : 'red',
                                fontSize: '11px',
                            }}>
                            {t('selectThree')}
                        </small>
                    </div>
                    <Tabs centered value={tabView} onChange={changeTab}>
                        <Tab
                            label={t('5 prochains jours')}
                            style={{ height: '20px' }}
                        />
                        <Tab
                            label={t("5 jours d'après")}
                            style={{ height: '20px' }}
                        />
                    </Tabs>
                    <DaysWrapper className="days">
                        <Timeslots
                            handleSelectTimeslot={handleSelectTimeslot}
                            timeslots={timeslots}
                            tabView={tabView}
                        />
                    </DaysWrapper>
                </>
            ) : null}
        </Fragment>
    )
}

export default TS
